<template>
    <div>
      <div v-if="isSmallScreen" class="pchome">
        <header>
          <img class="imgTop" src="@/assets/topbg.png" @click="advertisement"/>
        </header>
          <nav class="navbar">
            <div class="a_title" v-for="(item,index) in navbarList" :key="item.id">
              <a @click="scrollToSection(index, 'themeId' + item.id, 'pc', item.id)">{{ item.themeName }}</a>
            <div class="line"></div>
          </div>
          </nav>
          <div class="dao">
            <span id="shouye" @click="goHome">首页 ></span>
            <span id="myElement"> {{ title }} </span>
          </div>
          <div style="display: flex;justify-content: center;">
            <div class="yaow">
              <img src="@/assets/left.png"/>
                  <div id="text-container">{{ title }}</div>  
                <img src="@/assets/right.png"/>
            </div>
          </div>
          <section id="content">
            <div v-for="item in articleList" :key="item.articleId">
              <section id="leadership" class="section" v-if="item.img" @click="goDetail(item.articleId,item.title)">
                <div style="width:72vw;height:160px;display: flex;margin-top: 20px;">
                  <div style="width:256px;height:144px;display:flex;justify-content:center;align-items:center;margin-right: 20px;">
                    <img style="width:256px;height:144px;" :src="item.img"/>
                  </div>
                  <div style="padding-top: 20px;border-bottom: 2px solid #BDC0C3;height: 124px;width: 100%;">
                    <div class="wentitle">{{ item.title }}</div>
                    <div class="miaoshu">{{ item.intro }}</div>
                    <div style="font-size: 18px;color: #999;text-align: right;">{{ item.createTime ? item.createTime : '' }}</div>
                  </div>
                </div>
              </section>
              <section id="agriculture" class="section" v-else @click="goDetail(item.articleId,item.title)">
              <div style="width:100%;border-bottom: 2px solid #BDC0C3;">
              <div>
              <div class="wentitle">{{ item.title }}</div>
              <div class="miaoshu">{{ item.intro }}</div>
                <!-- <div style="font-size: 18px;color: #999;display: flex;justify-content: end;"> -->
                <div style="font-size: 18px;color: #999;text-align: right;margin: 5px 0;">{{ item.createTime ? item.createTime : '' }}</div>
                <!-- </div> -->
              </div>
              </div>
              </section>
            </div>
              
              <div id="loader" style="display:none;">
                <div style="display: flex; justify-content: center;">
                  <img src="@/assets/jiaz.gif" width="60px" height="60px" />
                </div>
              </div>
          </section>
        <div ref="loading" style="margin-bottom: 70px;"></div>
        <div class="bottombe">
          新华薪福：京ICP备2024057082号-1
        </div>
        <img src="@/assets/ding.png" width="70px" height="70px" id="to-top" v-if="toTop" @click="goTop" style="position:fixed;bottom:150px;right:50px;cursor: pointer;" />
      </div>
      <div v-else class="mobilehome">
        <img class="top" src="@/assets/img/top.png" @click="advertisement">
        <!-- 导航栏 -->
        <div class="mobileNavbar" id="Navbar">
          <ul id="ul">
              <!-- 获取动态数据后，先将第一项的visible设置为true，别的都设置为false,之后点击那个设置为true，别的设置为false-->
              <li v-for="(item,index) in navbarList" :key="item.id"><a :id="'link' + (index + 1)" :class="{'active': item.visible}" 
                @click="scrollToSection(index, 'themeId' + item.id, 'mobile', item.id)">{{ item.themeName }}
              </a>
            </li>
          </ul>
        </div>
        <div class="main">
          <div class="mobile_dao">
            <span id="shouye" @click="goHome">首页 ></span>
            <span id="myElement"> {{ title }} </span>
          </div>
            <div class="title">
                <div class="titleLeft"></div>
                <div class="titleText">{{ title }}</div>
                <div class="titleRight"></div>
            </div>
            <div class="content">
              <div v-for="item in articleList" :key="item.articleId">
                <div class="items goText" @click="goDetail(item.articleId,item.title)" v-if="item.img">
                    <div class="items-img">
                      <img :src="item.img">
                    </div>
                    <div class="items-text">
                      <div>{{ item.title }}</div>
                      <div>{{ item.intro }}</div>
                    </div>
                </div>
                <div class="zcsl goText" @click="goDetail(item.articleId,item.title)" v-else>
                  <div>{{ item.title }}</div>
                  <div>{{ item.intro }}</div>
                </div>
              </div>
                
            </div>
        </div>
        <div class="footer">
            <div style="margin-left: 82vw;display: none;" id="goTop">
                <img style="height: 13vw;width: 13vw;" src="@/assets/img/Group 2814.png">
            </div>
        </div>
        <div ref="loading" style="margin-bottom: 15vw;"></div>
        <div class="bah">
            新华薪福：京ICP备2023028182号-1
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  // axios.defaults.baseURL = 'https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/theme/'

  export default{
    data(){
      return{
        // 决定显示pc端还是mobile端
        isSmallScreen: true,
        // 导航栏数据
        navbarList:[],
        title: '', // 文章标题
        themeId: '', // 主题id
        articleList: [], // 文章列表
        autoPlayInterval: null,
        toTop: false,
        pageNum: 1,
        pageSize: 16,
        total: 0,
        scrollTimer: null
      }
    },
    async created(){
        // this.title = this.$route.query.title
        this.themeId = this.$route.query.themeId
        this.getNavbar()
        // console.log(this.navbarList[0].id == this.themeId)
        const data = await axios.post('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/theme/getArticleListByThemeId',{pageNum: this.pageNum,pageSize: this.pageSize,themeId: this.themeId})
        if(data.data.code === 200){
          this.articleList = data.data.extend.data.list
          this.total = data.data.extend.data.total
        }
    },
    mounted() {
      // 决定显示哪个元素
      this.screenWidth()
      window.addEventListener('resize', this.innerWidth)
      // 控制滑动顶部按钮是否显示
      window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
      // window.removeEventListener('resize', this.checkWidth)
      clearInterval(this.autoPlayInterval)
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods:{
      // 物理宽度
      screenWidth(){
        const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
        if(isInWeChatMiniProgram){
          this.isSmallScreen = false
          return
        }
        let width = screen.width
        if (width >= 550) {
          this.isSmallScreen = true
        } else {
          this.isSmallScreen = false
        }
      },
      // 窗口宽度
      innerWidth(){
        const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
        if(isInWeChatMiniProgram){
          this.isSmallScreen = false
          return
        }
        let width = window.innerWidth
        if (width >= 550) {
          this.isSmallScreen = true
        } else {
          this.isSmallScreen = false
        }
      },
      async getNavbar(){
        const res = await axios.get('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/index/getTheme')
        if(res.data.code === 200){
          const data = res.data.extend.data
          data.forEach((item,index) => {
            if(item.themeType !== 1){
              this.navbarList.push(item)
            }
          })
          this.navbarList.forEach((item,index) => {
            // if(index === 0){
            //   item.visible = true
            // }else{
              item.visible = false
            // }
            if(item.id == Number(this.themeId)){
              this.title = item.themeName
            }
          })
        }
      },
      // 跳转首页
      scrollToSection(index,elId,text,id){
          this.$router.push({
            name: 'home',
            params:{
              index: index + 1,
              elId: elId,
              text: text,
              id: id
            }
          })
      },
      goHome(){
        this.$router.push({path: '/home'})
      },
      // 跳转广告页
      advertisement(id){
        this.$router.push({
          path: '/advertisement',
        })
      },
      // 跳转详情页
      goDetail(articleId,title){
        this.$router.push({
          path: '/articleDetail',
          query:{
            articleId: articleId,
            title: title
          }
        })
      },
      // 控制返回顶部按钮显示/隐藏
      async handleScroll() {
        // if(this.isSmallScreen){
          const elementRect = this.$refs.loading.getBoundingClientRect()
          const distanceToTop = elementRect.top
          const top = window.innerHeight
          // console.log(distanceToTop - 50 < top,this.total < this.articleList.length)
          if(distanceToTop - 50 < top && this.total > this.articleList.length){
            // 防抖
            clearTimeout(this.scrollTimer) // 清除之前的定时器
            this.scrollTimer = setTimeout(async () => {
                this.pageNum += 1
                const data = await axios.post('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/theme/getArticleListByThemeId', { pageNum: this.pageNum, pageSize: this.pageSize, themeId: this.themeId })
                if (data.data.code === 200) {
                  data.data.extend.data.list.forEach(item => {
                    this.articleList.push(item)
                  })
                  this.total = data.data.extend.data.total
                }
            }, 500)
          }
        // }
        
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const screenHeight = window.innerHeight || document.documentElement.clientHeight
        if (scrollTop > screenHeight) {  
          this.toTop = true
        } else {  
          this.toTop = false
        }  
      },
      // 滚动到顶部
      goTop(){
        window.scrollTo({  
          top: 0,  
          behavior: 'smooth'  
        })
      },
    }
  }
  </script>
  
  <style lang="less" scoped>
  // pc样式
      body, html {margin: 0;padding: 0;font-family: Arial, sans-serif;font-size:32px;}
			header {height: 11vw;}
			header img{width: 100%; height: 100%;}
			.navbar {display: flex;justify-content: space-around;background-color:#005099;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); width: 80%;padding: 0 10%;}
			.a_title{width:14.2%;height:50px;cursor: pointer;}
			.navbar a {font-size:22px;width:100%;height:90%;color: #fff;text-decoration: none;position: relative;display: flex;justify-content: center;align-items: center;}
			.line{height:2px;width:80px;background-color:#fff;margin-left: calc(50% - 40px);display: none;}
			.dao{font-size: 18px;color:#666; cursor: pointer;line-height: 80px;padding-left: 13%;text-align: left;}
			.yaow{ width: 70%;height: 70px;display: flex;align-items: center;justify-content: center;}
			.yaow div{font-size: 28px;text-align: center;background-color: #fff;left: 50px;padding: 0 20px;}
			.yaow img{width: 319px;height: 22px;}
			.section {margin-bottom: 40px;width: 72vw;margin-left: 14vw;margin-top: 30px;}
			.miaoshu{font-size: 18px; color: #999;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;margin-top: 16px;margin-bottom: 4px;text-align: left;}
			.bottombe{height: 70px;width: 100%;background-color: #005099;font-size: 18px;color: #FFFFFF;line-height: 70px;text-align: center;position: fixed;bottom: 0;}
			.wentitle{font-weight: 600;font-size: 22px;cursor: pointer;text-align: left;}
			.wentitle:hover{color: #FB5733;}


  // mobile样式
  .top{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 9;
  }
  .mobileNavbar {  
    position: fixed;
    /* top: 15vw; */
    top: 70px;
    overflow-x:scroll;
    scrollbar-width: none; /* Firefox 浏览器 */  
    -ms-overflow-style: none; /* IE 和 Edge 浏览器 */  
    white-space: nowrap;
    width: 100vw;
    height: 50px;
    background-image: url('@/assets/img/Rectangle\ 1092.png'); 
    z-index: 9;
  }  
  .mobileNavbar ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none ;
    height: 50px;
  }
  .mobileNavbar ul li {  
    padding: 0;
    flex-shrink: 0;
    width: 25vw; 
    height: 100%;
    box-sizing: border-box;
  } 
  .mobileNavbar li a {  
    display: block;  
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    /* padding: 14px 0;   */
    text-decoration: none;
    font-size: 0.8rem;
    line-height: 50px;
  }
  .active{
    color: #fff !important;
    font-size: 0.95rem !important;
    font-weight: bold !important;
    background: url('@/assets/img/Rectangle\ 1183.png') no-repeat !important;
    background-position: center 80% !important;
    background-size: 13vw 1px !important;
  }
  #link6{
    margin-left: -3vw;
  }
  .main{
    margin: 120px auto 0;
    width: 95vw;
  }
  .mobile_dao{
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    color: #666666;
    font-size: 0.8rem;
    margin-bottom: -10px;
  }
    .breadCrumb{
      height: 10vw;
      line-height: 10vw;
      text-align: left;
      font-size: 0.7rem;
      color: #666666;
    }
    .title{
      padding: 0 12px;
      display: flex;
      height: 9vw;
      line-height: 9vw;
    }
    
    .titleText{
      width: 50%;
      text-align: center;
      font-weight: bold;
    }
    .titleLeft{
      background: url('@/assets/img/Group\ 2640.png') no-repeat;
    }
    .titleRight{
      background: url('@/assets/img/Group\ 2639.png') no-repeat;
    }
    .titleLeft,.titleRight{
      width: 25%;
      background-size: contain;
      background-position: center center;
    }
    

    .hkt{
      margin-top: 5vw;
  }
  .items{
      display: flex;
      margin-bottom: 1.5vw;
  }
  .items-img img{
      width: 32vw;
      height: 19vw;
      border-radius: 6px;
  }
  .items-text{
      margin-left: 6px;
      width: 61vw;
      height: 19vw;
      border-bottom: 1px solid #BDC0C3;
      div{
        text-align: left;
      }
  }
  .items-text > div:nth-child(2n - 1){
      height: 7.5vw;
      line-height: 7.5vw;
      font-weight: bold;
      font-size: 0.85rem;
      color: #333333;
      overflow: hidden; 
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .items-text > div:nth-child(2n){
      padding-bottom: 5px;
      font-size: 0.7rem;
      color: #666666;
      overflow: hidden; 
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .hkt-text{
      text-indent:2.25rem;
      font-size: 0.85rem;
      color: #666666;
  }
  .zcsl{
      margin-bottom: 2.5vw;
      div{
        text-align: left;
      }
  }
  .zcsl > div:nth-child(2n - 1){
      height: 8vw;
      line-height: 8vw;
      font-weight: bold;
      font-size: 0.85rem;
      color: #333333;
  }
  .zcsl > div:nth-child(2n){
      padding-bottom: 5px;
      font-size: 0.7rem;
      color: #666666;
      border-bottom: 1px solid #BDC0C3;
  }

  .footer{
      position: fixed;
      bottom: 20vw;
  }

  /* 备案号 */
  .bah{
      position: fixed;
      bottom: 0;
      width: 100vw;
      height: 15vw;
      line-height: 15vw;
      text-align: center;
      background-color: #005099;
      font-size: 0.8rem;
      color: #FFFFFF;
  }
  </style>
  